import React from 'react'

export const Calendar = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.3333 2.66675V8.00008"
      stroke="#11BB77"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.6667 2.66675V8.00008"
      stroke="#11BB77"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 12H28"
      stroke="#11BB77"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.3333 5.3335H6.66667C5.95942 5.3335 5.28115 5.61445 4.78105 6.11454C4.28095 6.61464 4 7.29292 4 8.00016V25.3335C4 26.0407 4.28095 26.719 4.78105 27.2191C5.28115 27.7192 5.95942 28.0002 6.66667 28.0002H25.3333C26.0406 28.0002 26.7189 27.7192 27.219 27.2191C27.719 26.719 28 26.0407 28 25.3335V8.00016C28 7.29292 27.719 6.61464 27.219 6.11454C26.7189 5.61445 26.0406 5.3335 25.3333 5.3335V5.3335Z"
      stroke="#11BB77"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 12V28"
      stroke="#11BB77"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 12V28"
      stroke="#11BB77"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 20H28"
      stroke="#11BB77"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
