import React from 'react'

export const DocumentSheet = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.666 17.3333H21.3327"
      stroke="#11BB77"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.666 22.6667H21.3327"
      stroke="#11BB77"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99967 4H20.229C20.9362 4.00015 21.6144 4.2812 22.1143 4.78133L25.885 8.552C26.3851 9.05198 26.6662 9.73015 26.6663 10.4373V25.3333C26.6663 26.0406 26.3854 26.7189 25.8853 27.219C25.3852 27.719 24.7069 28 23.9997 28H7.99967C7.29243 28 6.61415 27.719 6.11406 27.219C5.61396 26.7189 5.33301 26.0406 5.33301 25.3333V6.66667C5.33301 5.95942 5.61396 5.28115 6.11406 4.78105C6.61415 4.28095 7.29243 4 7.99967 4V4Z"
      stroke="#11BB77"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.6667 10.6667H21.3333C20.9797 10.6667 20.6406 10.5262 20.3905 10.2761C20.1405 10.0261 20 9.68696 20 9.33333V4"
      stroke="#11BB77"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.666 12H14.666"
      stroke="#11BB77"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
