import React from 'react'

export const DocumentListSquare = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.3333 28H6.66667C5.95942 28 5.28115 27.719 4.78105 27.219C4.28095 26.7189 4 26.0406 4 25.3333V6.66667C4 5.95942 4.28095 5.28115 4.78105 4.78105C5.28115 4.28095 5.95942 4 6.66667 4H25.3333C26.0406 4 26.7189 4.28095 27.219 4.78105C27.719 5.28115 28 5.95942 28 6.66667V25.3333C28 26.0406 27.719 26.7189 27.219 27.219C26.7189 27.719 26.0406 28 25.3333 28Z"
      stroke="#11BB77"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.6667 10.6666H14.6667"
      stroke="#11BB77"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6667 16H22.6667"
      stroke="#11BB77"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6667 21.3334H22.6667"
      stroke="#11BB77"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.33067 10.3334C9.26486 10.3339 9.20067 10.3539 9.14621 10.3909C9.09174 10.4278 9.04944 10.48 9.02461 10.541C8.99979 10.6019 8.99357 10.6689 9.00673 10.7334C9.01989 10.7979 9.05185 10.857 9.09857 10.9033C9.14529 10.9497 9.20469 10.9812 9.26928 10.9938C9.33386 11.0065 9.40075 10.9997 9.4615 10.9744C9.52226 10.9491 9.57416 10.9064 9.61067 10.8516C9.64718 10.7969 9.66666 10.7325 9.66667 10.6667C9.66667 10.6227 9.65796 10.5792 9.64104 10.5385C9.62412 10.4979 9.59933 10.4611 9.5681 10.4301C9.53686 10.3991 9.4998 10.3746 9.45905 10.358C9.4183 10.3414 9.37466 10.333 9.33067 10.3334Z"
      stroke="#11BB77"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.33067 15.6666C9.26486 15.6672 9.20067 15.6872 9.14621 15.7241C9.09174 15.7611 9.04944 15.8133 9.02461 15.8742C8.99979 15.9352 8.99357 16.0021 9.00673 16.0666C9.01989 16.1311 9.05185 16.1902 9.09857 16.2366C9.14529 16.2829 9.20469 16.3144 9.26928 16.3271C9.33386 16.3397 9.40075 16.333 9.4615 16.3077C9.52226 16.2824 9.57416 16.2396 9.61067 16.1849C9.64718 16.1301 9.66666 16.0658 9.66667 16C9.66667 15.956 9.65796 15.9124 9.64104 15.8718C9.62412 15.8312 9.59933 15.7943 9.5681 15.7633C9.53686 15.7323 9.4998 15.7078 9.45905 15.6912C9.4183 15.6746 9.37466 15.6663 9.33067 15.6666"
      stroke="#11BB77"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.33067 21C9.26486 21.0005 9.20067 21.0205 9.14621 21.0575C9.09174 21.0944 9.04944 21.1467 9.02461 21.2076C8.99979 21.2686 8.99357 21.3355 9.00673 21.4C9.01989 21.4645 9.05185 21.5236 9.09857 21.57C9.14529 21.6163 9.20469 21.6478 9.26928 21.6605C9.33386 21.6731 9.40075 21.6663 9.4615 21.641C9.52226 21.6157 9.57416 21.573 9.61067 21.5183C9.64718 21.4635 9.66666 21.3992 9.66667 21.3333C9.66667 21.2893 9.65796 21.2458 9.64104 21.2052C9.62412 21.1645 9.59933 21.1277 9.5681 21.0967C9.53686 21.0657 9.4998 21.0412 9.45905 21.0246C9.4183 21.008 9.37466 20.9997 9.33067 21"
      stroke="#11BB77"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
