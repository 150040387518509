import React from 'react'

export const ChartUpArrow = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 13.334L15.6666 18.6673L13 16.0007L10.3333 18.6673"
      stroke="#1B7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 13.334H21V17.334"
      stroke="#1B7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.3333 4H10.6667C6.98477 4 4 6.98477 4 10.6667V21.3333C4 25.0152 6.98477 28 10.6667 28H21.3333C25.0152 28 28 25.0152 28 21.3333V10.6667C28 6.98477 25.0152 4 21.3333 4Z"
      stroke="#1B7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
