import React from 'react'

export const ClockFast = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    fill="none"
    viewBox="0 0 32 32"
  >
    <path
      stroke="#1B7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M4 20h6.667M10.667 24h-4M10.667 28H9.333M15.624 10.648v6.202l4.875 2.972"
    />
    <path
      stroke="#1B7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M4.079 14.668A12 12 0 0128 16a12 12 0 01-12 12"
    />
  </svg>
)
