import React from 'react'

export const WalletCross = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.068 19.9336L7.93201 24.0696"
      stroke="#1B7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.068 24.0696L7.93201 19.9336"
      stroke="#1B7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33333 15.8748V10.7881C5.33333 9.12414 6.36399 7.63348 7.91999 7.04548L19.0573 2.84014C19.461 2.68758 19.8958 2.63528 20.3241 2.68774C20.7525 2.74021 21.1618 2.89586 21.5168 3.14134C21.8717 3.38681 22.1618 3.71478 22.3621 4.09707C22.5624 4.47937 22.6669 4.90456 22.6667 5.33614V9.80281"
      stroke="#1B7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.9987 18.8008C22.9549 18.801 22.9116 18.8098 22.8712 18.8267C22.8308 18.8436 22.7942 18.8683 22.7633 18.8994C22.7325 18.9304 22.7081 18.9673 22.6915 19.0078C22.6749 19.0483 22.6665 19.0917 22.6667 19.1355C22.6669 19.2014 22.6867 19.2657 22.7236 19.3204C22.7604 19.3751 22.8127 19.4176 22.8737 19.4426C22.9347 19.4676 23.0017 19.4739 23.0663 19.4608C23.1309 19.4477 23.1902 19.4157 23.2366 19.3689C23.2831 19.3221 23.3146 19.2625 23.3272 19.1978C23.3398 19.1331 23.3329 19.0661 23.3074 19.0053C23.282 18.9445 23.239 18.8926 23.1841 18.8562C23.1291 18.8198 23.0646 18.8005 22.9987 18.8008Z"
      stroke="#1B7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.216 27.9994H24C26.2 27.9994 28 26.1994 28 23.9994V13.8008C28 12.7399 27.5786 11.7225 26.8284 10.9724C26.0783 10.2222 25.0609 9.80078 24 9.80078H7.99999C7.29275 9.80078 6.61447 10.0817 6.11438 10.5818C5.61428 11.0819 5.33333 11.7602 5.33333 12.4674V16.3421"
      stroke="#1B7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 29.3321C9.03689 29.332 8.08322 29.1422 7.19346 28.7735C6.30369 28.4049 5.49524 27.8646 4.81428 27.1835C4.13332 26.5024 3.59317 25.6939 3.22468 24.804C2.8562 23.9142 2.66658 22.9605 2.66667 21.9974C2.66676 21.0343 2.85655 20.0806 3.22519 19.1908C3.59384 18.3011 4.13414 17.4926 4.81522 16.8117C5.49631 16.1307 6.30485 15.5906 7.19469 15.2221C8.08452 14.8536 9.03822 14.664 10.0013 14.6641C11.9464 14.6642 13.8118 15.4371 15.1871 16.8126C16.5623 18.1881 17.3348 20.0536 17.3347 21.9987C17.3345 23.9438 16.5616 25.8092 15.1861 27.1845C13.8106 28.5597 11.9451 29.3322 10 29.3321"
      stroke="#1B7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
