import React from 'react'

export const MoneyConversionCircle = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    fill="none"
    viewBox="0 0 32 32"
  >
    <path
      stroke="#1B7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M8.293 6.813A11.917 11.917 0 0116 4a12 12 0 0112 12c0 1.93-.467 3.747-1.276 5.361l-3.575-1.786M23.707 25.187A11.917 11.917 0 0116 28 12 12 0 014 16c0-1.93.467-3.747 1.276-5.361l3.575 1.786M16 9.334v1.667M16 22.667V21"
    />
    <path
      stroke="#1B7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M19.316 13.333A2.493 2.493 0 0016.833 11h-1.826a2.34 2.34 0 00-.57 4.61l3.124.785a2.34 2.34 0 01-.569 4.61h-1.827a2.493 2.493 0 01-2.484-2.338"
    />
  </svg>
)
