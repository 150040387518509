import React from 'react'

export const DocumentCheck = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M24.5523 8.552L20.7817 4.78133C20.2817 4.2812 19.6035 4.00015 18.8963 4H9.33366C8.62641 4 7.94814 4.28095 7.44804 4.78105C6.94794 5.28115 6.66699 5.95942 6.66699 6.66667V25.3333C6.66699 26.0406 6.94794 26.7189 7.44804 27.219C7.94814 27.719 8.62641 28 9.33366 28H22.667C23.3742 28 24.0525 27.719 24.5526 27.219C25.0527 26.7189 25.3337 26.0406 25.3337 25.3333V10.4373C25.3335 9.73015 25.0525 9.05198 24.5523 8.552V8.552Z"
      stroke="#11BB77"
      strokeWidth="1.38462"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25.3337 10.6667H20.0003C19.6467 10.6667 19.3076 10.5262 19.0575 10.2761C18.8075 10.0261 18.667 9.68696 18.667 9.33333V4"
      stroke="#11BB77"
      strokeWidth="1.38462"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.667 22.6667H19.3337"
      stroke="#11BB77"
      strokeWidth="1.38462"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.6663 15.3333L15.333 18.6667L13.333 16.6667"
      stroke="#11BB77"
      strokeWidth="0.923077"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
