import React from 'react'

export const IdCard = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4548 10.8787C14.0175 11.4413 14.3335 12.2044 14.3335 13C14.3335 13.7957 14.0175 14.5587 13.4548 15.1214C12.8922 15.684 12.1292 16 11.3335 16C10.5379 16 9.77479 15.684 9.21218 15.1214C8.64957 14.5587 8.3335 13.7957 8.3335 13C8.3335 12.6061 8.41109 12.2159 8.56186 11.852C8.71262 11.488 8.9336 11.1573 9.21218 10.8787C9.49076 10.6001 9.82148 10.3791 10.1855 10.2284C10.5494 10.0776 10.9395 10 11.3335 10C12.1292 10 12.8922 10.3161 13.4548 10.8787"
      stroke="#11BB77"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.66675 24.6666V7.38796C2.66675 5.88529 3.88541 4.66663 5.38808 4.66663H26.6667C27.374 4.66663 28.0523 4.94758 28.5524 5.44767C29.0525 5.94777 29.3334 6.62605 29.3334 7.33329V24.6666C29.3334 25.3739 29.0525 26.0521 28.5524 26.5522C28.0523 27.0523 27.374 27.3333 26.6667 27.3333H5.33341C4.62617 27.3333 3.94789 27.0523 3.4478 26.5522C2.9477 26.0521 2.66675 25.3739 2.66675 24.6666Z"
      stroke="#11BB77"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 12.6666H25.3333"
      stroke="#11BB77"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.2 18H20"
      stroke="#11BB77"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.1267 21.9999C15.8136 21.2129 15.2714 20.538 14.5704 20.0625C13.8693 19.5871 13.0417 19.333 12.1947 19.3333H10.472C9.40537 19.3333 8.37871 19.7359 7.59604 20.4613C7.13379 20.8891 6.773 21.4148 6.54004 21.9999"
      stroke="#11BB77"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
