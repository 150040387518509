import React from 'react'

export const Devices = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.5 25.332H4.00002C3.26402 25.332 2.66669 24.7347 2.66669 23.9987V22.6654C2.66669 21.9294 3.26402 21.332 4.00002 21.332H18.5"
      stroke="#1B7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.6667 6.66667C26.6667 5.19333 25.4733 4 24 4H6.66667C5.19333 4 4 5.19333 4 6.66667V21.3333"
      stroke="#1B7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.1667 10.668H26.6667C28.14 10.668 29.3333 11.8613 29.3333 13.3346V25.3346C29.3333 26.808 28.14 28.0013 26.6667 28.0013H21.1667C19.6933 28.0013 18.5 26.808 18.5 25.3346V13.3346C18.5 11.8613 19.6933 10.668 21.1667 10.668Z"
      stroke="#1B7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.6667 10.668V6.66797"
      stroke="#1B7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.7466 25H25.08"
      stroke="#1B7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
