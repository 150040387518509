import React from 'react'

export const SmileyFace = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.33301 13.3336C9.85967 12.687 10.5797 12.3203 11.333 12.3203C12.0863 12.3203 12.7863 12.687 13.333 13.3336"
      stroke="#11BB77"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.667 13.3336C19.1937 12.687 19.9137 12.3203 20.667 12.3203C21.4203 12.3203 22.1203 12.687 22.667 13.3336"
      stroke="#11BB77"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.4854 7.51474C25.5997 8.62905 26.4836 9.95193 27.0867 11.4078C27.6898 12.8638 28.0001 14.4242 28.0001 16.0001C28.0001 17.5759 27.6898 19.1364 27.0867 20.5923C26.4836 22.0482 25.5997 23.3711 24.4854 24.4854C23.3711 25.5997 22.0482 26.4836 20.5923 27.0867C19.1364 27.6898 17.5759 28.0001 16.0001 28.0001C14.4242 28.0001 12.8638 27.6898 11.4078 27.0867C9.95193 26.4836 8.62905 25.5997 7.51474 24.4854C5.26429 22.235 4 19.1827 4 16.0001C4 12.8175 5.26429 9.76519 7.51474 7.51474C9.76519 5.26429 12.8175 4 16.0001 4C19.1827 4 22.235 5.26429 24.4854 7.51474"
      stroke="#11BB77"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.6663 19.584C20.6663 19.584 18.9157 21.3333 15.9997 21.3333C13.0823 21.3333 11.333 19.584 11.333 19.584"
      stroke="#11BB77"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
