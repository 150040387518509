import React from 'react'

export const Rocket = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.2538 14.7461L7.82715 24.1728"
      stroke="#11BB77"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.84388 17.3328L4.22655 16.2421C4.11101 16.2149 4.00485 16.1573 3.91903 16.0753C3.8332 15.9933 3.77082 15.8898 3.73834 15.7757C3.70586 15.6615 3.70444 15.5407 3.73425 15.4258C3.76405 15.3109 3.82399 15.2061 3.90788 15.1221L7.57988 11.4501C7.82725 11.2026 8.16199 11.0623 8.51188 11.0594L12.6519 11.0234"
      stroke="#11BB77"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.5055 11.9662L27.5828 6.78223C27.6504 6.45734 27.636 6.12074 27.5411 5.80276C27.4461 5.48479 27.2736 5.19544 27.0389 4.96079C26.8043 4.72614 26.5149 4.55356 26.1969 4.45861C25.879 4.36366 25.5424 4.34933 25.2175 4.4169L20.0335 5.49423C18.5073 5.81117 17.1072 6.56756 16.0055 7.67023L11.7521 11.9222C10.1295 13.5455 9.07326 15.6486 8.74014 17.9196L8.72547 18.0169C8.62105 18.7314 8.68503 19.4605 8.91233 20.1459C9.13963 20.8313 9.52395 21.4542 10.0346 21.9647C10.5453 22.4753 11.1683 22.8595 11.8537 23.0866C12.5392 23.3137 13.2683 23.3775 13.9828 23.2729L14.0801 23.2582C16.3513 22.9257 18.4545 21.8694 20.0775 20.2462L24.3295 15.9942C25.4322 14.8925 26.1885 13.4924 26.5055 11.9662V11.9662Z"
      stroke="#11BB77"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.8662 6.28516C20.9493 8.38821 23.6112 11.0501 25.7142 14.1332"
      stroke="#11BB77"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.667 23.1557L15.7577 27.773C15.7849 27.8885 15.8425 27.9947 15.9245 28.0805C16.0065 28.1663 16.1099 28.2287 16.2241 28.2612C16.3383 28.2937 16.459 28.2951 16.5739 28.2653C16.6888 28.2355 16.7937 28.1755 16.8777 28.0917L20.5497 24.4197C20.7971 24.1723 20.9374 23.8375 20.9403 23.4877L20.9763 19.3477"
      stroke="#11BB77"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
