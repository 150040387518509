import React from 'react'

export const AppleAppStore = () => (
  <svg
    width="14"
    height="18"
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2979_14103)">
      <path
        d="M11.5641 9.532C11.5448 7.38065 13.3212 6.34783 13.4015 6.29747C12.4019 4.83503 10.8463 4.63468 10.2913 4.61111C8.96812 4.47719 7.70817 5.39001 7.03748 5.39001C6.36679 5.39001 5.32969 4.6304 4.23258 4.65183C2.78835 4.67325 1.45876 5.49072 0.715214 6.78282C-0.783659 9.38308 0.332729 13.2369 1.79196 15.3475C2.50658 16.3793 3.35726 17.5417 4.47579 17.4988C5.5536 17.456 5.96073 16.8024 7.2614 16.8024C8.56207 16.8024 8.92848 17.4989 10.0674 17.4785C11.2255 17.456 11.9605 16.4242 12.6698 15.3893C13.4883 14.1915 13.8269 13.0322 13.8462 12.9712C13.8204 12.9604 11.5877 12.1044 11.5641 9.532Z"
        fill="#1F2D27"
      />
      <path
        d="M9.42158 3.21488C10.0151 2.49597 10.4158 1.49635 10.3055 0.5C9.45042 0.534322 8.41552 1.06999 7.80159 1.78889C7.25198 2.42426 6.76986 3.44205 6.90051 4.41812C7.85408 4.49204 8.82794 3.93272 9.42158 3.21488Z"
        fill="#1F2D27"
      />
    </g>
    <defs>
      <clipPath id="clip0_2979_14103">
        <rect
          width="14"
          height="17"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
)
