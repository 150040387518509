import React from 'react'

export const ScrollTop = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.3333 23.4883L19.6667 16.8667L13 23.4883"
      stroke="#1F2D27"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <line
      x1="13.5"
      y1="15.5"
      x2="26.5"
      y2="15.5"
      stroke="#1F2D27"
      strokeLinecap="round"
    />
  </svg>
)
