import React from 'react'

export const BeachChair = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    fill="none"
    viewBox="0 0 32 32"
  >
    <path
      stroke="#1B7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M16 20v-8M12 12c0-4.4 1.733-8 4-8s4 3.6 4 8M9.467 24L8 28M21.2 24l1.467 4M6 24h14.8c1.467 0 2.8-.533 4-1.333l2.4-1.867c.667-.533.933-1.467.667-2.267-.4-1.333-2-1.733-3.067-.933l-2.4 1.867c-.4.4-1.067.533-1.6.533H6c-1.067 0-2 .933-2 2s.933 2 2 2z"
    />
    <path
      stroke="#1B7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M5.333 12h21.333c0-4-5.333-8-10.666-8-5.334 0-10.667 4-10.667 8z"
    />
  </svg>
)
