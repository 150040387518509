import React from 'react'

export const DocumentGraphCheck = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    fill="none"
    viewBox="0 0 32 32"
  >
    <path
      stroke="#1B7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M14.667 28H7.704a2.533 2.533 0 01-2.37-2.667V6.667A2.533 2.533 0 017.703 4h16.592a2.533 2.533 0 012.37 2.667v8M10.667 18h4M10.667 22.666h4M24.921 22.342l-1.988 1.983-1.192-1.19"
    />
    <path
      stroke="#1B7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M25.333 18.666h-4a2.667 2.667 0 00-2.666 2.667v4a2.667 2.667 0 002.666 2.666h4A2.667 2.667 0 0028 25.333v-4a2.667 2.667 0 00-2.667-2.667zM21.333 9.334l-4 4-3.618-2.667-3.048 2.667"
    />
    <path
      stroke="#1B7"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
      d="M21.333 12V9.335h-2.666"
    />
  </svg>
)
